import useBackendRequest from '../useBackendRequest';

interface IFilesApi {
  getFile: (fileId: string) => Promise<Blob>;
}

export default function useFilesApi(): IFilesApi {
  const { makeRequest } = useBackendRequest();

  function getFile(fileId: string): Promise<Blob> {
    return makeRequest<Blob, Blob>(
      `/api/files/${fileId}`,
      'get',
      response => { return response; },
      undefined
    );
  }

  return {
    getFile
  }
}