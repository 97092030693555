import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { UserOutlined, BookOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Layout as AntdLayout, Menu, Button, Popover, Space, Row, Col } from 'antd';
import useAuth from '../../hooks/useAuth';
import './Layout.scss';

const { Header, Content, Sider } = AntdLayout;

export function Layout() {
  const auth = useAuth();

  function renderUserPopover() {
    return <Space direction="vertical">
      <Button size="small" icon={<LogoutOutlined />} onClick={() => auth.signout()}>
        Выход
      </Button>
    </Space>
  }

  return (
    <AntdLayout className='layout'>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <Row wrap={false}>
          <Col flex="none">
            <div className="layout__logo">
              <Link to="/">SignalReports</Link>
            </div>
          </Col>
          <Col flex="auto"></Col>
          <Col flex="none">
            <Popover placement="bottom" content={renderUserPopover()} trigger="click">
              <Space direction="horizontal">
                <span style={{ color: 'white' }}>{auth.currentUser?.userName}</span>
                <Avatar size={32} icon={<UserOutlined />} />
              </Space>
            </Popover>
          </Col>
        </Row>
      </Header>
      <AntdLayout hasSider style={{ marginTop: 64 }}>
        <Sider
          width={300}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 64,
            bottom: 0,
          }}
        >
          <Menu theme="dark" mode="inline">
            <Menu.Item key="packages" icon={<BookOutlined />}>
              <Link to="/packages">Версии пакетов</Link>
            </Menu.Item>
            <Menu.Item key="reports" icon={<BookOutlined />}>
              <Link to="/reports">Сигнальные отчеты</Link>
            </Menu.Item>
            <Menu.Item key="persons" icon={<BookOutlined />}>
              <Link to="/persons">Физ. лица</Link>
            </Menu.Item>
            <Menu.Item key="companies" icon={<BookOutlined />}>
              <Link to="/companies">Юр. лица</Link>
            </Menu.Item>
            <Menu.SubMenu key="activePackages" title="Управление постановками">
              <Menu.Item key="activePackage_CompaniesAndPersonsWithPassport" icon={<BookOutlined />}>
                <Link to="/activePackage/CompaniesAndPersonsWithPassport">ЮЛ и ФЛ с ПД</Link>
              </Menu.Item>
              <Menu.Item key="activePackage_PersonsWithoutPassport" icon={<BookOutlined />}>
                <Link to="/activePackage/PersonsWithoutPassport">ФЛ без ПД</Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Sider>
        <AntdLayout style={{ marginLeft: 300 }}>
          <Content style={{ margin: '24px 16px 24px 16px', overflow: 'initial', background: '#fff' }}>
            <div style={{ padding: 24 }}>
              <Outlet />
            </div>
          </Content>
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  )
}