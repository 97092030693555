import React, { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { Result } from 'antd';
import { Layout } from './components/Layout';
import { Login, RequireAuth } from './components/Security';
import { NoMatch } from './components/NoMatch';
import { PageLoading } from './components/PageLoading';
import { CompanyCard, CompanyList, PersonCard, PersonList } from './components/Subjects';
import { ActivePackageCard, PackageList } from './components/PackageItems';
import { ReportList } from './components/Reports';
import useAuth from './hooks/useAuth';
import './styles/App.scss';
import { FieldsComposition } from './hooks/backend/api/usePackagesApi';

type UserCheckingState = "Checked" | "Checking" | "Error";

function App() {
  const routes = useRoutes([
    {
      path: '/',
      element: <RequireAuth><Layout /></RequireAuth>,
      children: [
        { index: true, element: <h1>Dashboard</h1> },
        { path: 'logout', element: <h1>Logout</h1> },
        { path: 'packages', element: <PackageList /> },
        { path: 'reports', element: <ReportList /> },
        { path: 'persons/:id', element: <PersonCard /> },
        { path: 'persons', element: <PersonList /> },
        { path: 'companies', element: <CompanyList /> },
        { path: 'companies/:id', element: <CompanyCard /> },
        {
          path: 'activePackage/CompaniesAndPersonsWithPassport',
          element: <ActivePackageCard
            fieldsComposition={FieldsComposition.CompaniesAndPersonsWithPassport}
          />
        },
        {
          path: 'activePackage/PersonsWithoutPassport',
          element: <ActivePackageCard
            fieldsComposition={FieldsComposition.PersonsWithoutPassport}
          />
        },
      ]
    },
    { path: '/login', element: <Login /> },
    { path: '*', element: <NoMatch /> }
  ]);
  const auth = useAuth();
  const [userCheckingState, setUserCheckingState] = useState<UserCheckingState | undefined>(undefined);

  useEffect(() => {
    async function fetchData() {
      setUserCheckingState("Checking")
      try {
        await auth.refresh();
        setUserCheckingState("Checked")
      }
      catch (error) {
        setUserCheckingState("Error")
      }
    }

    fetchData();
  }, []);

  function render() {
    switch (userCheckingState) {
      case "Checked":
        return routes;
      case "Error":
        return <Result status="error" />
      default:
        return <PageLoading />
    }
  }

  return render();
}

export default App;