import React, { useEffect, useRef, useState } from 'react';
import { PageHeader, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import useAuth from '../../hooks/useAuth';
import usePackagesApi, { IPackageListItem } from '../../hooks/backend/api/usePackagesApi';
import { Wrapper, WrapperState } from '../Wrapper';
import { PackageListColumns } from './PackageListColumns';
import { usePackageListConfig } from './PackageListConfig';
import { defaultPage, defaultPageSize } from '../../types/PagingConfig';
import useFilesApi from '../../hooks/backend/api/useFilesApi';
import saveFile from '../../services/saveFile';

interface IPackageTableItem extends IPackageListItem {
  key: string;
}

export const PackageList = () => {
  const unmounted = useRef(false);
  const packagesApi = usePackagesApi();
  const filesApi = useFilesApi();
  const {paging, setPaging} = usePackageListConfig();
  const [isReferencesLoading, setIsReferencesLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [initError, setInitError] = useState<any>(undefined);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [packages, setPackages] = useState<IPackageTableItem[]>([]);
  const {currentUser} = useAuth();
  const [totalRows, setTotalRows] = useState<number>(0);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  async function getPackages() {
    try {
      setInitError(undefined);
      setLoading(true);

      const packages = await packagesApi.getPackages(
        paging.page,
        paging.pageSize);

      if (!unmounted.current) {
        const convertedPackages: IPackageTableItem[] = packages.list.map(
          (r) => ({
            ...r,
            key: r.id,
          })
        );
        setTotalRows(packages.totalRowCount);
        setPackages(convertedPackages);
      }
    } catch (error) {
      if (!unmounted.current) {
        setInitError(error);
      }
    } finally {
      if (!unmounted.current) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (currentUser?.canViewMonitoring()) {
      getPackages();
    }
  }, [currentUser, paging]);

  useEffect(() => {
    if (currentUser === null) {
      setWrapperState('loading');
    } else if (!currentUser.canViewMonitoring()) {
      setWrapperState('accessDenied');
    } else if (initError) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [currentUser, initError]);

  function handleOnChange(pagination: TablePaginationConfig) {
    console.log('onChange');
    setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
  }

  async function onDownloadClick(id: string) {
    var data = await filesApi.getFile(id);
    saveFile(data, 'application/xml', `MonitoringPackageContent${id}`, 'xml');
  }

  const renderTable = () => {
    if (!currentUser) {
      return null;
    }
    return (
      <Table
        className="u-mt-4"
        columns={PackageListColumns(onDownloadClick)}
        loading={isLoading || isReferencesLoading}
        dataSource={packages}
        scroll={{ x: 900 }}
        onChange={handleOnChange}
        pagination={{
          current: paging.page,
          position: ['bottomCenter'],
          pageSize: paging.pageSize,
          total: totalRows,
          showSizeChanger: false
        }}
      />
    );
  };

  return (
    <Wrapper state={wrapperState} error={initError}>
      <PageHeader title="Пакеты на постановку в мониторинг" />
      {renderTable()}
    </Wrapper>
  );
};