import moment from "moment-timezone";
import useBackendRequest from '../useBackendRequest';
import IPagedList from '../../../types/PagedList';

export interface IReportListItem {
  id: string;
  createdTimestamp: string;
  providerFileName: string;
  providerFileId: string | null;
  state: string;
  signalsCount: number;
  fieldsCompositionName: string;
  packageName: string;
}

interface IReportListItemResponse {
  id: string;
  createdTimestamp: string;
  createdTimestampFormatted: string;
  providerFileName: string;
  providerFileId: string | null;
  state: string;
  signalsCount: number;
  fieldsCompositionName: string;
  packageName: string;
}

interface IReportsApi {
  getReports: (page: number, pageSize: number) => Promise<IPagedList<IReportListItem>>;
}

export default function useReportsApi(): IReportsApi {
  const { makeRequest } = useBackendRequest();

  function getReports(page: number, pageSize: number): Promise<IPagedList<IReportListItem>> {

    return makeRequest<IPagedList<IReportListItemResponse>, IPagedList<IReportListItem>>(
      `/api/reports?page=${page}&pageSize=${pageSize}`,
      'get',
      response => {
        let list = response.list.map<IReportListItem>(value => {
          let date = moment(value.createdTimestamp);
          return {
            ...value,
            createdTimestampFormatted: date.tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss:SSS Z')
          }
        })
        return {
          list: list,
          page: response.page,
          pageSize: response.pageSize,
          totalRowCount: response.totalRowCount
        }
      },
      undefined
    );
  }

  return {
    getReports
  }
}