import { atom, selector, useSetRecoilState, useRecoilValue } from 'recoil';
import IPagingConfig, { IPaging, defaultPage, defaultPageSize } from '../../types/PagingConfig';

const pagingAtom = atom<IPaging>({
  key: 'packageList_pagingStore',
  default: { page: defaultPage, pageSize: defaultPageSize }
})

const pagingSelector = selector({
  key: 'packageList_paging',
  get: ({ get }) => get(pagingAtom)
})

export function usePackageListConfig(): IPagingConfig {
  const setPaging = useSetRecoilState(pagingAtom);
  const paging = useRecoilValue(pagingSelector);

  return {
    paging,
    setPaging: (page: number, pageSize: number) => setPaging({ page, pageSize })
  }
}