import moment from "moment-timezone";
import useBackendRequest from '../useBackendRequest';
import IPagedList from '../../../types/PagedList';

export interface IPackageListItem {
  id: string;
  createdTimestamp: string;
  name: string;
  fileId: string | null;
  state: string;
  subjectsCount: number;
  fieldsCompositionName: string;
}

interface IPackageListItemResponse {
  id: string;
  createdTimestamp: string;
  createdTimestampFormatted: string;
  name: string;
  fileId: string | null;
  state: string;
  subjectsCount: number;
  fieldsCompositionName: string;
}

enum PackageSettingType {
  Manual = 1,
  Automatic = 2
}

export interface IActivePackageCard {
  settingType: string;
  settingTypeValue: PackageSettingType;
  lastPackageVersionTimestamp: string;
  lastPackageVersionTimestampFormatted: string;
  lastPackageVersionSubjectsCount: number;
  packageVersionsCountByCurrentMonth: number;
  activeSubjectsCountByCurrentMonth: number;
}

interface IActivePackageCardResponse {
  settingType: string;
  settingTypeValue: PackageSettingType;
  lastPackageVersionTimestamp: string;
  lastPackageVersionSubjectsCount: number;
  packageVersionsCountByCurrentMonth: number;
  activeSubjectsCountByCurrentMonth: number;
}

export enum FieldsComposition {
  CompaniesAndPersonsWithPassport = 1,
  PersonsWithoutPassport = 2
}

interface IPackagesApi {
  getPackages: (page: number, pageSize: number) => Promise<IPagedList<IPackageListItem>>;
  getActivePackage: (fieldsComposition: FieldsComposition) => Promise<IActivePackageCard>;
  initManualSetting: (fieldsComposition: FieldsComposition) => Promise<void>;
  getPotentialActivePackageSubjectsCount: (fieldsComposition: FieldsComposition) => Promise<number>;
}

export default function usePackagesApi(): IPackagesApi {

  const { makeRequest } = useBackendRequest();

  function getPackages(page: number, pageSize: number): Promise<IPagedList<IPackageListItem>> {

    return makeRequest<IPagedList<IPackageListItemResponse>, IPagedList<IPackageListItem>>(
      `/api/packages?page=${page}&pageSize=${pageSize}`,
      'get',
      response => {
        let list = response.list.map<IPackageListItem>(value => {
          let date = moment(value.createdTimestamp);
          return {
            ...value,
            createdTimestampFormatted: date.tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss:SSS Z')
          }
        })
        return {
          list: list,
          page: response.page,
          pageSize: response.pageSize,
          totalRowCount: response.totalRowCount
        }
      },
      undefined
    );
  }

  function getActivePackage(fieldsComposition: FieldsComposition): Promise<IActivePackageCard> {
    return makeRequest<IActivePackageCardResponse, IActivePackageCard>(
      `/api/packages/activePackage?fieldsComposition=${fieldsComposition}`,
      'get',
      response => {
        return {
          ...response,
          lastPackageVersionTimestampFormatted:
            response.lastPackageVersionTimestamp
              ? moment(response.lastPackageVersionTimestamp).tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss:SSS Z')
              : "",
        }
      },
      undefined
    );
  }

  function initManualSetting(fieldsComposition: FieldsComposition) {
    return makeRequest<void, void>(
      `/api/packages/activePackage/initManualSetting`,
      'post',
      () => { },
      { fieldsComposition: fieldsComposition }
    );
  }

  function getPotentialActivePackageSubjectsCount(fieldsComposition: FieldsComposition): Promise<number> {
    return makeRequest<number, number>(
      `/api/packages/activePackage/potentialSubjectsCount?fieldsComposition=${fieldsComposition}`,
      'get',
      response => { return response; },
      undefined
    );
  }

  return {
    getPackages,
    getActivePackage,
    getPotentialActivePackageSubjectsCount,
    initManualSetting
  }
}