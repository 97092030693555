import React, { useEffect, useState } from 'react';
import { Collapse, Space, Row, Col, Button, Form, Select, DatePicker, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { IClientListItem } from '../../../hooks/backend/api/useClientsApi';
import { IFilterValues, usePersonListConfig } from './PersonListConfig';
import { InfoPopover } from '../../InfoPopover';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

interface IFilterProps {
    clientsOptions: IClientListItem[];
    onSearch: (values: IFilterValues) => void;
    loading?: boolean;
}

export function PersonListFilter({
    clientsOptions,
    onSearch: onSearch,
    loading,
}: IFilterProps) {
    const { isFilterOpen, openFilter, closeFilter, filter } = usePersonListConfig();
    const [activePanels, setActivePanels] = useState<string[]>([]);
    const panelKey = '1';
    const [form] = Form.useForm<IFilterValues>();

    useEffect(() => {
        setActivePanels(isFilterOpen ? [panelKey] : [])
    }, [isFilterOpen])

    function handleOnChange(key: string | string[]) {
        if (Array.isArray(key) && key.length > 0) {
            openFilter();
        }
        else {
            closeFilter();
        }
    }

    return (
        <Collapse
            ghost
            className="filter-row__collapse"
            activeKey={activePanels}
            onChange={handleOnChange}
        >
            <Panel header="Фильтры" key={panelKey}>
                <Form
                    form={form}
                    onFinish={onSearch}
                    initialValues={filter}
                >
                    <div className="filter-row">
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item name="client">
                                    <Select
                                        size="middle"
                                        placeholder="Клиент"
                                        allowClear
                                        options={clientsOptions?.map((option) => ({
                                            value: option.id,
                                            label: option.name,
                                        }))}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col>
                                <WithPopoverWrapper info="Поиск производится по времени, что отображается в интерфейсе">
                                    <Form.Item label="Дата/время создания субъекта" name="dates">
                                        <RangePicker
                                            placeholder={['с', 'по']}
                                            showTime={{ format: 'HH:mm' }}
                                            format="YYYY-MM-DD HH:mm"
                                            allowEmpty={[true, true]}
                                            allowClear
                                        />
                                    </Form.Item>
                                </WithPopoverWrapper>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col>
                                <Form.Item name="lastName">
                                    <Input
                                        size="middle"
                                        placeholder="Фамилия"
                                        allowClear
                                        suffix={<InfoPopover info="Будут найдены записи которые начинаются или соответствуют данной строке" />}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="firstName">
                                    <Input
                                        size="middle"
                                        placeholder="Имя"
                                        allowClear
                                        suffix={<InfoPopover info="Будут найдены записи которые начинаются или соответствуют данной строке" />}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="middleName">
                                    <Input
                                        size="middle"
                                        placeholder="Отчество"
                                        allowClear
                                        suffix={<InfoPopover info="Будут найдены записи которые начинаются или соответствуют данной строке" />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col>
                                <Form.Item name="passportSeries">
                                    <Input
                                        size="middle"
                                        placeholder="Серия паспорта"
                                        allowClear
                                        suffix={<InfoPopover info="Будут найдены записи которые начинаются или соответствуют данной строке" />}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="passportNumber">
                                    <Input
                                        size="middle"
                                        placeholder="Номер паспорта"
                                        allowClear
                                        suffix={<InfoPopover info="Будут найдены записи которые начинаются или соответствуют данной строке" />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Button
                                    type="primary"
                                    block
                                    loading={loading}
                                    htmlType="submit"
                                    icon={<SearchOutlined />}
                                >
                                    Искать
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Panel>
        </Collapse>
    );
}

interface WithPopoverWrapperProps {
    info?: string | JSX.Element;
    popoverWidth?: number;
    children: React.ReactNode;
}
function WithPopoverWrapper({
    info,
    popoverWidth,
    children,
}: WithPopoverWrapperProps) {
    return (
        <Space align="start" className="with-popover">
            <div className="with-popover__content">{children}</div>
            {info && (
                <InfoPopover
                    info={info}
                    popoverWidth={popoverWidth}
                    style={{ paddingTop: 7 }}
                />
            )}
        </Space>
    );
}