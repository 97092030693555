import { Table } from 'antd';
import React from 'react';

export function PeriodTable(source: any) {

    const columns = [
        {
            title: 'Клиент',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Ключ клиента',
            dataIndex: 'clientKey',
            key: 'clientKey',
        },
        {
            title: 'Старт постановки',
            dataIndex: 'startDate',
            key: 'startDate',
        },
        {
            title: 'Конец постановки',
            dataIndex: 'finishDate',
            key: 'finishDate',
        }
    ];

    return <Table dataSource={source} columns={columns} pagination={false} />;
}
