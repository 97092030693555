import Axios, { Method as AxiosMethod, AxiosError } from "axios";

export interface HttpError {
    statusCode: number | undefined;
    data: any;
}

export type HttpMethod = "get" | "post";

const httpClient = {
    makeRequest<TResponse, TResult>(url: string,
        method: HttpMethod,
        transformResponse: (response: TResponse) => TResult,
        requestData: any): Promise<TResult> {
        const axiosMethod = method as AxiosMethod;
        const acceptHeader = { Accept: 'application/json' };
        return new Promise((resolve, reject) => {
            Axios.request<TResponse>({
                method: axiosMethod,
                url: url,
                data: requestData,
                headers: { ...acceptHeader },
            }).then(response => {
                resolve(transformResponse(response.data));
            }).catch((error: AxiosError) => {
                reject({
                    statusCode: error?.response?.status,
                    data: error?.response?.data
                });
            });
        });
    }
};

export default httpClient;