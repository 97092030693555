import React, { useEffect, useRef, useState } from 'react';
import { PageHeader, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import usePersonsApi, { IPersonListItem } from '../../../hooks/backend/api/usePersonsApi';
import { Wrapper, WrapperState } from '../../Wrapper';
import { PersonListColumns } from './PersonListColumns';
import { usePersonListConfig, IFilterValues, } from './PersonListConfig';
import { PersonListFilter } from './PersonListFilter';
import useClientsApi, { IClientListItem } from '../../../hooks/backend/api/useClientsApi';
import { defaultPage, defaultPageSize } from '../../../types/PagingConfig';

interface IPersonTableItem extends IPersonListItem {
    key: string;
}

export function PersonList() {
    const unmounted = useRef(false);
    const personsApi = usePersonsApi();
    const clientsApi = useClientsApi();
    const {paging, setFilter, setPaging, filter} = usePersonListConfig();
    const [isReferencesLoading, setIsReferencesLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [initError, setInitError] = useState<any>(undefined);
    const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
    const [subjects, setSubjects] = useState<IPersonTableItem[]>([]);
    const {currentUser} = useAuth();
    const navigate = useNavigate();
    const [totalRows, setTotalRows] = useState<number>(0);
    const [allClients, setAllClients] = useState<IClientListItem[]>([]);

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);

    async function getReferences() {
        setInitError(undefined);
        setIsReferencesLoading(true);
        try {
            const receivedClients = await clientsApi.getClients();
        
            if (!unmounted.current) {
              setAllClients(receivedClients);
            }
          }
          catch (error) {
            if (!unmounted.current) {
              setInitError(error);
            }
          }
          finally {
            if (!unmounted.current) {
              setIsReferencesLoading(false);
            }
          }
    }

    async function getSubjects() {
        try {
            setInitError(undefined);
            setLoading(true);

            const dateFrom = filter.dates?.length === 2 ? filter.dates[0]?.format('YYYY-MM-DD HH:mm') : undefined;
            const dateTo = filter.dates?.length === 2 ? filter.dates[1]?.format('YYYY-MM-DD HH:mm') : undefined;

            const subjects = await personsApi.getPersons(
                paging.page,
                paging.pageSize,
                filter.client,
                filter.firstName,
                filter.lastName,
                filter.middleName,
                filter.passportNumber,
                filter.passportSeries,
                dateFrom,
                dateTo);

            if (!unmounted.current) {
                const convertedSubjects: IPersonTableItem[] = subjects.list.map(
                    (r) => ({
                        ...r,
                        key: r.id,
                    })
                );
                setTotalRows(subjects.totalRowCount);
                setSubjects(convertedSubjects);
            }
        } catch (error) {
            if (!unmounted.current) {
                setInitError(error);
            }
        } finally {
            if (!unmounted.current) {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if (currentUser?.canViewMonitoring()) {
            getReferences();
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser?.canViewMonitoring()) {
            getSubjects();
        }
    }, [currentUser, paging, filter]);

    useEffect(() => {
        if (currentUser === null) {
            setWrapperState('loading');
        } else if (!currentUser.canViewMonitoring()) {
            setWrapperState('accessDenied');
        } else if (initError) {
            setWrapperState('hasErrors');
        } else {
            setWrapperState(null);
        }
    }, [currentUser, initError]);

    function redirectOnViewSubject(id: string) {
        navigate(`/persons/${id}`);
    }

    function onFilterSearch(values: IFilterValues) {
        setFilter(values);
    }

    function handleOnChange(pagination: TablePaginationConfig) {
        setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
    }

    const renderTable = () => {
        if (!currentUser) {
            return null;
        }
        return (
            <Table
                className="u-mt-4"
                columns={PersonListColumns(redirectOnViewSubject)}
                loading={isLoading || isReferencesLoading}
                dataSource={subjects}
                scroll={{ x: 900 }}
                onChange={handleOnChange}
                pagination={{
                    current: paging.page,
                    position: ['bottomCenter'],
                    pageSize: paging.pageSize,
                    total: totalRows,
                    showSizeChanger: false
                }}
            />
        );
    };

    return (
        <Wrapper state={wrapperState} error={initError}>
            <PageHeader title="Физические лица" />
            <PersonListFilter
                clientsOptions={allClients}
                onSearch={onFilterSearch}
                loading={isLoading || isReferencesLoading} />
            {renderTable()}
        </Wrapper>
    );
};