import moment from "moment-timezone";
import useBackendRequest from '../useBackendRequest';
import IPagedList from '../../../types/PagedList';

interface IPeriod {
  id: string;
  clientName: string;
  clientId: string;
  startDate: string;
  finishDate: string;
}

interface IEventPeriod extends IPeriod {
  type: string;
}

export interface ICompanyCard {
  id: string;
  signalsCount: number;
  periods: IPeriod[];
  periodEvents: IEventPeriod[];
  createdTimestamp: string;
  createdTimestampFormatted: string;
  legalName: string;
  ogrn: string;
  inn: string;
}

interface ICompanyCardResponse {
  id: string;
  signalsCount: number;
  periods: IPeriod[];
  periodEvents: IEventPeriod[];
  createdTimestamp: string;
  legalName: string;
  ogrn: string;
  inn: string;
}

export interface ICompanyListItem {
  id: string;
  createdTimestamp: string;
  createdTimestampFormatted: string;
  legalName: string;
  ogrn: string;
  inn: string;
}

interface ICompanyListItemResponse {
  id: string;
  createdTimestamp: string;
  createdTimestampFormatted: string;
  legalName: string;
  ogrn: string;
  inn: string;
}

interface ICompaniesApi {
  getCompanies: (
    page: number,
    pageSize: number,
    clientId: string | undefined,
    companyName: string | undefined,
    ogrn: string | undefined,
    inn: string | undefined,
    createdFrom: string | undefined,
    createdTill: string | undefined) => Promise<IPagedList<ICompanyListItem>>;
  getCompany: (id: string) => Promise<ICompanyCard>;
}

export default function useCompaniesApi(): ICompaniesApi {
  const { makeRequest } = useBackendRequest();

  function getCompanies(
    page: number,
    pageSize: number,
    clientId: string | undefined,
    companyName: string | undefined,
    ogrn: string | undefined,
    inn: string | undefined,
    createdFrom: string | undefined,
    createdTill: string | undefined): Promise<IPagedList<ICompanyListItem>> {

    let url = `/api/companies?page=${page}&pageSize=${pageSize}`;

    if (clientId != null) {
      url += `&clientId=${clientId}`;
    }

    if (companyName) url += `&companyName=${companyName}`;
    if (ogrn) url += `&ogrn=${ogrn}`;
    if (inn) url += `&inn=${inn}`;

    if (createdFrom) {
      url += `&createdFrom=${moment(createdFrom).toISOString()}`;
    }
    if (createdTill) {
      url += `&createdTill=${moment(createdTill).toISOString()}`;
    }

    return makeRequest<IPagedList<ICompanyListItemResponse>, IPagedList<ICompanyListItem>>(
      url,
      'get',
      response => {
        let list = response.list.map<ICompanyListItem>(value => {
          let date = moment(value.createdTimestamp);
          return {
            ...value,
            createdTimestampFormatted: date.tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss:SSS Z')
          }
        })
        return {
          list: list,
          page: response.page,
          pageSize: response.pageSize,
          totalRowCount: response.totalRowCount
        }
      },
      undefined
    );
  }

  function getCompany(id: string): Promise<ICompanyCard> {
    return makeRequest<ICompanyCardResponse, ICompanyCard>(
      `/api/companies/${id}`,
      'get',
      response => {
        let date = moment(response.createdTimestamp);
        let periods = response.periods.map(p => {
          let startDate = moment(p.startDate);
          let finishDate = moment(p.finishDate);
          return {
            ...p,
            startDate: startDate.tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss:SSS Z'),
            finishDate: finishDate.tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss:SSS Z')
          }
        })
        return {
          ...response,
          periods: periods,
          createdTimestampFormatted: date.tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss:SSS Z')
        }
      },
      undefined
    );
  }

  return {
    getCompanies,
    getCompany
  }
}