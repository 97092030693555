import { Moment } from 'moment';
import { atom, selector, useSetRecoilState, useRecoilValue } from 'recoil';
import IPagingConfig, { IPaging, defaultPage, defaultPageSize } from '../../../types/PagingConfig';
import IFilterConfig from '../../../types/FilterConfig';

export interface IFilterValues {
    client: string | undefined;
    dates: Moment[] | null;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    passportSeries?: string;
    passportNumber?: string;
}

export const emptyFilter: IFilterValues = {
    client: undefined,
    dates: null,
    firstName: undefined,
    lastName: undefined,
    middleName: undefined,
    passportSeries: undefined,
    passportNumber: undefined
}

const pagingAtom = atom<IPaging>({
    key: 'personList_pagingStore',
    default: { page: defaultPage, pageSize: defaultPageSize }
  })
  
  const pagingSelector = selector({
    key: 'personList_paging',
    get: ({ get }) => get(pagingAtom)
  })
  
  const filterIsOpenAtom = atom<boolean>({
    key: 'personList_filterIsOpenStore',
    default: false
  })
  
  const filterIsOpenSelector = selector({
    key: 'personList_filterIsOpen',
    get: ({ get }) => get(filterIsOpenAtom)
  })
  
  const filterAtom = atom<IFilterValues>({
    key: 'personList_filterStore',
    default: emptyFilter
  })
  
  const filterSelector = selector({
    key: 'personList_filter',
    get: ({ get }) => get(filterAtom)
  })
  
  interface IConfig extends IPagingConfig, IFilterConfig<IFilterValues> { }
  
  export function usePersonListConfig(): IConfig {
    const setPaging = useSetRecoilState(pagingAtom);
    const paging = useRecoilValue(pagingSelector);
    const setFilterIsOpen = useSetRecoilState(filterIsOpenAtom);
    const filterIsOpen = useRecoilValue(filterIsOpenSelector);
    const setFilter = useSetRecoilState(filterAtom);
    const filter = useRecoilValue(filterSelector);
  
    return {
      isFilterOpen: filterIsOpen,
      openFilter: () => setFilterIsOpen(true),
      closeFilter: () => setFilterIsOpen(false),
      filter: filter,
      resetFilter: () => setFilter(emptyFilter),
      setFilter: (values: IFilterValues) => setFilter(values),
      paging,
      setPaging: (page: number, pageSize: number) => setPaging({ page, pageSize })
    }
  }