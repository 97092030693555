import { Card, Col, Divider, PageHeader, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import usePersonsApi, { IPersonCard } from '../../../hooks/backend/api/usePersonsApi';
import { Wrapper, WrapperState } from '../../Wrapper';
import { PeriodTable } from '../PeriodTable';
import { PeriodEventTable } from '../PeriodEventTable';

export function PersonCard() {
    const unmounted = useRef(false);
    const personsApi = usePersonsApi();
    const [isLoading, setIsLoading] = useState(false);
    const [initError, setInitError] = useState<any>(undefined);
    const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
    const [subject, setSubject] = useState<IPersonCard | null>(null);
    const {currentUser} = useAuth();
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);
    
    async function fetchData(id: string) {
        setInitError(undefined);
        setIsLoading(true);
        try {
            const subject = await personsApi.getPerson(id);

            if (!unmounted.current) {
                setSubject(subject);
            }
        } catch (error) {
            if (!unmounted.current) {
                setInitError(error);
            }
        } finally {
            if (!unmounted.current) {
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        if (currentUser?.canViewMonitoring() && params?.id) {
            fetchData(params.id);
        }
    }, [currentUser, params]);

    useEffect(() => {
        if (currentUser === null) {
            setWrapperState('loading');
        }
        else if (!currentUser.canViewMonitoring()) {
            setWrapperState('accessDenied');
        } else if (isLoading) {
            setWrapperState('loading');
        } else if (initError) {
            setWrapperState('hasErrors');
        } else {
            setWrapperState(null);
        }
    }, [currentUser, isLoading, initError]);

    return (
        <Wrapper state={wrapperState} error={initError}>
            <div className="container left">
                <Card
                    bordered={false}
                    title={<PageHeader title={"Физическое лицо"} onBack={() => navigate('/persons')} />}
                >
                    <Divider orientation="left">Поля</Divider>
                    <Row gutter={16}>
                        <Col span={12} >
                            <p>Создан: {subject?.createdTimestampFormatted}</p>
                            <p>Количество сигналов: {subject?.signalsCount}</p>
                            <p>Фамилия: {subject?.lastName}</p>
                            <p>Имя: {subject?.firstName}</p>
                            <p>Отчество: {subject?.middleName}</p>
                            <p>Серия паспорта: {subject?.documentSeries}</p>
                            <p>Номер паспорта: {subject?.documentNumber}</p>
                            <p>Дата рождения: {subject?.birthDate}</p>
                        </Col>
                    </Row>
                    <Divider orientation="left">Периоды</Divider>
                    {PeriodTable(subject?.periods.map((value, index) => ({ key: `period_${index}`, ...value })))}
                    <Divider orientation="left">Детализация</Divider>
                    {PeriodEventTable(subject?.periodEvents.map((value, index) => ({ key: `periodEvent_${index}`, ...value })))}
                </Card>
            </div>
        </Wrapper>
    );
}