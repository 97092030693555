import { HttpMethod } from '../../services/httpClinet'
import makeBackendRequest from '../../services/backend/makeBackendRequest';
import IBackendError from "../../types/BackendError";
import useAuth from '../../hooks/useAuth';

interface IBackendRequest {
  makeRequest<TResponse, TResult>(
    url: string,
    method: HttpMethod,
    transformResponse: (response: TResponse) => TResult,
    requestData: any): Promise<TResult>;
}

export default function useBackendRequest(): IBackendRequest {
  const auth = useAuth();

  async function makeRequest<TResponse, TResult>(
    url: string,
    method: HttpMethod,
    transformResponse: (response: TResponse) => TResult,
    requestData: any): Promise<TResult> {
      try {
        return await makeBackendRequest(
          url,
          method,
          transformResponse,
          requestData
        );    
      }
      catch (error) {
        const backendError = error as IBackendError;
        if (backendError && backendError.statusCode === 401) {
          auth.reset();
        }
        throw error;
      }
    }

  return {
    makeRequest
  }
}