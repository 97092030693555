import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Modal, PageHeader, Row, Space } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import useAuth from '../../hooks/useAuth';
import usePackagesApi, { IActivePackageCard, FieldsComposition } from '../../hooks/backend/api/usePackagesApi';
import IBackendError from '../../types/BackendError';
import { Wrapper, WrapperState } from '../Wrapper';

interface IActivePackageCardProps {
    fieldsComposition: FieldsComposition;
}

export const ActivePackageCard = (props: IActivePackageCardProps) => {
    const unmounted = useRef(false);
    const packagesApi = usePackagesApi();
    const [isLoading, setIsLoading] = useState(false);
    const [initError, setInitError] = useState<any>(undefined);
    const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
    const [activePackage, setActivePackage] = useState<IActivePackageCard | null>(null);
    const { currentUser } = useAuth();

    useEffect(() => {
        return () => {
            unmounted.current = true;
        };
    }, []);
    async function fetchData() {
        setInitError(undefined);
        setIsLoading(true);
        try {
            const activePackage = await packagesApi.getActivePackage(props.fieldsComposition);

            if (!unmounted.current) {
                setActivePackage(activePackage);
            }
        } catch (error) {
            if (!unmounted.current) {
                setInitError(error)
            }
        } finally {
            if (!unmounted.current) {
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        if (currentUser?.canViewMonitoring()) {
            fetchData();
        }
    }, [currentUser, props.fieldsComposition]);

    useEffect(() => {
        if (currentUser === null) {
            setWrapperState('loading');
        }
        else if (!currentUser.canViewMonitoring()) {
            setWrapperState('accessDenied');
        } else if (isLoading) {
            setWrapperState('loading');
        } else if (initError) {
            setWrapperState('hasErrors');
        } else {
            setWrapperState(null);
        }
    }, [currentUser, isLoading, initError]);

    async function initManualPackageSetting() {
        let potentialSubjectsCount = await packagesApi.getPotentialActivePackageSubjectsCount(
            props.fieldsComposition);
        Modal.confirm({
            title: 'Вы уверены в своих действиях?',
            content: `На постановку будет отправлено ${potentialSubjectsCount} субъектов.`,
            onOk: async () => {
                try {
                    await packagesApi.initManualSetting(props.fieldsComposition)
                    let activePackage = await packagesApi.getActivePackage(props.fieldsComposition);
                    setActivePackage(activePackage);
                } catch (error) {
                    if (!unmounted.current) {
                        Modal.error({
                            title: 'Возникла ошибка'
                        });
                    }
                }
            }
        })
    }

    function renderTitle() {
        const titleMain = 'Управление постановками';

        switch (props.fieldsComposition) {
            case FieldsComposition.CompaniesAndPersonsWithPassport:
                return `${titleMain} (юр. лица и физ. лица с паспортными данными)`
            case FieldsComposition.PersonsWithoutPassport:
                return `${titleMain} (физ. лица без паспортных данных)`
            default:
                return titleMain;
        }
    }

    return (
        <Wrapper state={wrapperState} error={initError}>
            <div className="container left">
                <Card
                    title={<PageHeader title={renderTitle()} />}
                    bordered={false}
                >
                    <Row gutter={16}>
                        <Col span={12} >
                            <p>Тип постановок: {activePackage?.settingType}</p>
                            <p>Последняя постановка: {activePackage?.lastPackageVersionTimestampFormatted}</p>
                            <p>Постановок в этом месяце: {activePackage?.packageVersionsCountByCurrentMonth}</p>
                            <p>Активные субъекты в этом месяце: {activePackage?.activeSubjectsCountByCurrentMonth}</p>
                        </Col>
                        <Col span={12} >
                            {currentUser?.canEditMonitoring() && <Space>
                                <Button
                                    onClick={() => initManualPackageSetting()}
                                    block
                                    type="primary"
                                    icon={<SendOutlined />}
                                >
                                    Отправить пакет
                                </Button>
                            </Space>}
                        </Col>
                    </Row>
                </Card>
            </div>
        </Wrapper>
    );
}