import React from 'react';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { IPersonListItem } from '../../../hooks/backend/api/usePersonsApi';

type TColumnProps = ColumnProps<IPersonListItem>;

export function PersonListColumns(redirectOnViewSubject: (id: string) => void,): TColumnProps[] {
    return [
        {
            title: 'Дата создания',
            dataIndex: 'createdTimestampFormatted',
            key: 'createdTimestamp',
            width: 250
        },
        {
            title: 'Фамилия',
            dataIndex: 'lastName',
            key: 'lastName',
            width: 300,
            render: (text, record) => (
                <div style={{maxWidth:'300px'}}>
                    {record.lastName}
                </div>
            )
        },
        {
            title: 'Имя',
            dataIndex: 'firstName',
            key: 'firstName',
            width: 300,
            render: (text, record) => (
                <div style={{maxWidth:'300px'}}>
                    {record.firstName}
                </div>
            )
        },
        {
            title: 'Отчество',
            dataIndex: 'middleName',
            key: 'middleName',
            width: 300,
            render: (text, record) => (
                <div style={{maxWidth:'300px'}}>
                    {record.middleName}
                </div>
            )
        },
        {
            title: 'Серия паспорта',
            dataIndex: 'documentSeries',
            key: 'documentSeries',
        },
        {
            title: 'Номер паспорта',
            dataIndex: 'documentNumber',
            key: 'documentNumber',
        },
        {
            title: 'Дата рождения',
            dataIndex: 'birthDate',
            key: 'birthDate',
        },
        {
            title: 'Действия',
            key: 'action',
            width: 220,
            render: (text, record) => (
                <Button
                    onClick={() => redirectOnViewSubject(record.id)}
                    block
                    type="primary"
                    icon={<SearchOutlined />}
                >
                    Просмотреть
                </Button>
            ),
        }
    ];
};
