
import { Moment } from 'moment';
import { atom, selector, useSetRecoilState, useRecoilValue } from 'recoil';
import IPagingConfig, { IPaging, defaultPage, defaultPageSize } from '../../../types/PagingConfig';
import IFilterConfig from '../../../types/FilterConfig';

export interface IFilterValues {
  client: string | undefined;
  dates: Moment[] | null;
  companyName?: string;
  inn?: string;
  ogrn?: string;
}

export const emptyFilter: IFilterValues = {
  client: undefined,
  dates: null,
  companyName: undefined,
  inn: undefined,
  ogrn: undefined
}

const pagingAtom = atom<IPaging>({
  key: 'companyList_pagingStore',
  default: { page: defaultPage, pageSize: defaultPageSize }
})

const pagingSelector = selector({
  key: 'companyList_paging',
  get: ({ get }) => get(pagingAtom)
})

const filterIsOpenAtom = atom<boolean>({
  key: 'companyList_filterIsOpenStore',
  default: false
})

const filterIsOpenSelector = selector({
  key: 'companyList_filterIsOpen',
  get: ({ get }) => get(filterIsOpenAtom)
})

const filterAtom = atom<IFilterValues>({
  key: 'companyList_filterStore',
  default: emptyFilter
})

const filterSelector = selector({
  key: 'companyList_filter',
  get: ({ get }) => get(filterAtom)
})

interface IConfig extends IPagingConfig, IFilterConfig<IFilterValues> { }

export function useCompanyListConfig(): IConfig {
  const setPaging = useSetRecoilState(pagingAtom);
  const paging = useRecoilValue(pagingSelector);
  const setFilterIsOpen = useSetRecoilState(filterIsOpenAtom);
  const filterIsOpen = useRecoilValue(filterIsOpenSelector);
  const setFilter = useSetRecoilState(filterAtom);
  const filter = useRecoilValue(filterSelector);

  return {
    isFilterOpen: filterIsOpen,
    openFilter: () => setFilterIsOpen(true),
    closeFilter: () => setFilterIsOpen(false),
    filter: filter,
    resetFilter: () => setFilter(emptyFilter),
    setFilter: (values: IFilterValues) => setFilter(values),
    paging,
    setPaging: (page: number, pageSize: number) => setPaging({ page, pageSize })
  }
}