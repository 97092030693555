import moment from "moment-timezone";
import useBackendRequest from '../useBackendRequest';
import IPagedList from '../../../types/PagedList';

interface IPeriod {
  id: string;
  clientName: string;
  clientId: string;
  startDate: string;
  finishDate: string;
}

interface IEventPeriod extends IPeriod {
  type: string;
}

export interface IPersonListItem {
  id: string;
  createdTimestamp: string;
  createdTimestampFormatted: string;
  firstName: string;
  lastName: string;
  middleName: string;
  birthDate: string;
  documentSeries: string;
  documentNumber: string;
}

interface IPersonListItemResponse {
  id: string;
  createdTimestamp: string;
  createdTimestampFormatted: string;
  firstName: string;
  lastName: string;
  middleName: string;
  birthDate: string;
  documentSeries: string;
  documentNumber: string;
}

export interface IPersonCard {
  id: string;
  signalsCount: number;
  periods: IPeriod[];
  periodEvents: IEventPeriod[];
  createdTimestamp: string;
  createdTimestampFormatted: string;
  firstName: string;
  lastName: string;
  middleName: string;
  birthDate: string;
  documentSeries: string;
  documentNumber: string;
}

interface IPersonCardResponse {
  id: string;
  signalsCount: number;
  periods: IPeriod[];
  periodEvents: IEventPeriod[];
  createdTimestamp: string;
  firstName: string;
  lastName: string;
  middleName: string;
  birthDate: string;
  documentSeries: string;
  documentNumber: string;
}

interface IPersonsApi {
  getPersons: (
    page: number,
    pageSize: number,
    clientId: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
    middleName: string | undefined,
    documentNumber: string | undefined,
    documentSeries: string | undefined,
    createdFrom: string | undefined,
    createdTill: string | undefined) => Promise<IPagedList<IPersonListItem>>;
  getPerson: (id: string) => Promise<IPersonCard>;
}

export default function usePersonsApi(): IPersonsApi {
  const { makeRequest } = useBackendRequest();

  function getPersons(
    page: number,
    pageSize: number,
    clientId: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
    middleName: string | undefined,
    documentNumber: string | undefined,
    documentSeries: string | undefined,
    createdFrom: string | undefined,
    createdTill: string | undefined): Promise<IPagedList<IPersonListItem>> {

    let url = `/api/persons?page=${page}&pageSize=${pageSize}`;

    if (clientId != null) {
      url += `&clientId=${clientId}`;
    }

    if (firstName) url += `&firstName=${firstName}`;
    if (lastName) url += `&lastName=${lastName}`;
    if (middleName) url += `&middleName=${middleName}`;
    if (documentNumber) url += `&documentNumber=${documentNumber}`;
    if (documentSeries) url += `&documentSeries=${documentSeries}`;

    if (createdFrom) {
      url += `&createdFrom=${moment(createdFrom).toISOString()}`;
    }
    if (createdTill) {
      url += `&createdTill=${moment(createdTill).toISOString()}`;
    }

    return makeRequest<IPagedList<IPersonListItemResponse>, IPagedList<IPersonListItem>>(
      url,
      'get',
      response => {
        let list = response.list.map<IPersonListItem>(value => {
          const createdTimestamp = moment(value.createdTimestamp);
          const birthDate = moment(value.birthDate);
          return {
            ...value,
            createdTimestampFormatted: createdTimestamp.tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss:SSS Z'),
            birthDate: birthDate.format('DD.MM.YYYY')
          }
        })
        return {
          list: list,
          page: response.page,
          pageSize: response.pageSize,
          totalRowCount: response.totalRowCount
        }
      },
      undefined
    );
  }

  function getPerson(id: string): Promise<IPersonCard> {
    return makeRequest<IPersonCardResponse, IPersonCard>(
      `/api/persons/${id}`,
      'get',
      response => {
        const createdTimestamp = moment(response.createdTimestamp);
        const birthDate = moment(response.birthDate);
        const periods = response.periods.map(p => {
          let startDate = moment(p.startDate);
          let finishDate = moment(p.finishDate);
          return {
            ...p,
            startDate: startDate.tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss:SSS Z'),
            finishDate: finishDate.tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss:SSS Z')
          }
        })
        return {
          ...response,
          periods: periods,
          createdTimestampFormatted: createdTimestamp.tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss:SSS Z'),
          birthDate: birthDate.format('DD.MM.YYYY')
        }
      },
      undefined
    );
  }

  return {
    getPersons,
    getPerson
  }
}