import useBackendRequest from '../useBackendRequest';

interface IClientListItemResponse {
  id: string;
  name: string;
}

export interface IClientListItem {
  id: string;
  name: string;
}

interface IClientsApi {
  getClients(): Promise<IClientListItem[]>;
}

export default function useClientsApi(): IClientsApi {
  const { makeRequest } = useBackendRequest();

  function getClients(): Promise<IClientListItem[]> {
    return makeRequest<IClientListItemResponse[], IClientListItem[]>(
      '/api/clients',
      'get',
      response => response,
      undefined
    );
  }

  return {
    getClients
  }
}