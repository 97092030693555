import React from 'react';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { ICompanyListItem } from '../../../hooks/backend/api/useCompaniesApi';

type TColumnProps = ColumnProps<ICompanyListItem>;

export function companyListColumns(redirectOnViewSubject: (id: string) => void,): TColumnProps[] {
    return [
        {
            title: 'Дата создания',
            dataIndex: 'createdTimestampFormatted',
            key: 'createdTimestamp',
            width: 250
        },
        {
            title: 'Название компании',
            dataIndex: 'legalName',
            key: 'legalName',
            width: 500,
            render: (text, record) => (
                <div style={{maxWidth:'500px'}}>
                    {record.legalName}
                </div>
            )
        },
        {
            title: 'Инн',
            dataIndex: 'inn',
            key: 'inn',
        },
        {
            title: 'Огрн',
            dataIndex: 'ogrn',
            key: 'ogrn',
        },
        {
            title: 'Действия',
            key: 'action',
            width: 220,
            render: (text, record) => (
                <Button
                    onClick={() => redirectOnViewSubject(record.id)}
                    block
                    type="primary"
                    icon={<SearchOutlined />}
                >
                    Просмотреть
                </Button>
            ),
        }
    ];
};
