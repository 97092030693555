import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { IPackageListItem } from '../../hooks/backend/api/usePackagesApi';

type TColumnProps = ColumnProps<IPackageListItem>;

export const PackageListColumns = (onDownloadClick: (id: string) => void): TColumnProps[] => {
    return [
        {
            title: 'Дата создания',
            dataIndex: 'createdTimestampFormatted',
            key: 'createdTimestamp',
        },
        {
            title: 'Имя пакета',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Состав полей',
            dataIndex: 'fieldsCompositionName',
            key: 'fieldsCompositionName',
        },
        {
            title: 'Статус',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'Количество субъектов',
            dataIndex: 'subjectsCount',
            key: 'subjectsCount',
            width: 150,
        },
        {
            title: 'Действие',
            key: 'action',
            width: 180,
            render: (text, record) => (
                <Button
                    disabled={record.fileId === null}
                    onClick={() => { if (record.fileId) onDownloadClick(record.fileId) }}
                    block
                    type="primary"
                    icon={<DownloadOutlined />}
                >
                    Скачать
                </Button>
            ),
        }
    ];
};
