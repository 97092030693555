import React, { PropsWithChildren, useState, useEffect } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Result, Typography } from 'antd';
import { PageLoading } from '../PageLoading';
import { WrapperState } from './';
import IBackendError, { generalErrorKey } from '../../types/BackendError';
import './Wrapper.scss';

const { Paragraph, Title } = Typography;

interface IError {
  fieldName: string | undefined;
  message: string;
}

interface IWrapperProps {
  state: WrapperState | null;
  error?: any;
  noLoader?: boolean;
}

export function Wrapper({
  error,
  state,
  children,
  noLoader,
}: PropsWithChildren<IWrapperProps>) {
  const [errors, setErrors] = useState<IError[]>([]);

  useEffect(() => {
    setErrors([]);

    if (error) {
      const receivedErrors: IError[] = [];

      const backendError = error as IBackendError;

      if (backendError && typeof backendError.getGroupedMessages === 'function') {
        const groupedErrors = backendError.getGroupedMessages();
        Object.keys(groupedErrors)?.forEach(key => {
          const fieldName = key === generalErrorKey ? undefined : key;

          groupedErrors[key]?.forEach(errorMessage => receivedErrors.push({
            fieldName: fieldName,
            message: errorMessage
          }))
        })
      }

      setErrors(receivedErrors);
    }
  }, [error])

  function renderContent() {
    switch (state) {
      case 'accessDenied': {
        return <Result status="error" title="Доступ запрещен"></Result>;
      }
      case 'hasErrors': {
        return (
          <Result status="error" title="При обработке запроса произошли ошибки">
            {
              errors && errors.length > 0 && (
                <div className="desc">
                  <Paragraph>
                    <Title level={5}>Ошибки:</Title>
                  </Paragraph>
                  {
                    errors.map((error, index) => (
                      <Paragraph key={`error_${index}`}>
                        <CloseCircleOutlined className="page-wrapper__error-icon u-mr-1" />
                        {error.fieldName ? `${error.fieldName} - ${error.message}` : error.message}
                      </Paragraph>
                    ))
                  }
                </div>
              )
            }
          </Result>
        );
      }
      case 'loading': {
        return noLoader ? null : <PageLoading />;
      }
      default: {
        return children;
      }
    }
  }

  return <div className="page-wrapper">{renderContent()}</div>;
}
