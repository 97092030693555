import makeBackendRequest from "../makeBackendRequest";

interface IUserPermissionsResponse {
  canViewMonitoring: boolean;
  canEditMonitoring: boolean;
}

interface ICurrentUserResponse {
  userName: string;
  permissions: IUserPermissionsResponse;
}

export interface IUserPermissions {
  canViewMonitoring: boolean;
  canEditMonitoring: boolean;
}

export interface ICurrentUser {
  userName: string;
  permissions: IUserPermissions;
}

interface IAccountApi {
  login(userName: string, password: string): Promise<ICurrentUser>;
  logout(): Promise<void>;
  getCurrentUser(): Promise<ICurrentUser | null>;
}

const accountApi: IAccountApi = {
  login(userName: string, password: string): Promise<ICurrentUser> {
    return makeBackendRequest<ICurrentUserResponse, ICurrentUser>(
      '/api/account/login',
      "post",
      response => ({ ...response }),
      { userName: userName, password: password }
    );
  },

  logout(): Promise<void> {
    return makeBackendRequest<void, void>(
      '/api/account/logout',
      "post",
      () => { },
      undefined
    );
  },

  getCurrentUser(): Promise<ICurrentUser | null> {
    return makeBackendRequest<ICurrentUserResponse | null, ICurrentUser | null>(
      '/api/account/getCurrentUser',
      "get",
      response => {
        if (response) {
          return { ...response };
        }
        else {
          return null
        }
      },
      undefined
    );
  },
}

export default accountApi;