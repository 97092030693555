import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { IReportListItem } from '../../hooks/backend/api/useReportsApi';

type TColumnProps = ColumnProps<IReportListItem>;

export const reportListColumns = (onDownloadClick: (id: string) => void): TColumnProps[] => {
    return [
        {
            title: 'Дата создания',
            dataIndex: 'createdTimestampFormatted',
            key: 'createdTimestamp',
        },
        {
            title: 'Имя пакета',
            dataIndex: 'packageName',
            key: 'packageName',
        },
        {
            title: 'Состав полей',
            dataIndex: 'fieldsCompositionName',
            key: 'fieldsCompositionName',
        },
        {
            title: 'Статус',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'Количество сигналов',
            dataIndex: 'signalsCount',
            key: 'signalsCount',
            width: 150,
        },
        {
            title: 'Файл',
            key: 'providerFileName',
            width: 250,
            render: (text, record) => (record.providerFileName ? record.providerFileName : '-')
        },
        {
            title: 'Действие',
            key: 'action',
            width: 180,
            render: (text, record) => (
                <Button
                    disabled={record.providerFileId === null}
                    onClick={() => { if (record.providerFileId) onDownloadClick(record.providerFileId) }}
                    block
                    type="primary"
                    icon={<DownloadOutlined />}
                >
                    Скачать
                </Button>
            ),
        }
    ];
};
