import React, { useEffect, useRef, useState } from 'react';
import { PageHeader, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import useAuth from '../../hooks/useAuth';
import saveFile from '../../services/saveFile';
import useReportsApi, { IReportListItem } from '../../hooks/backend/api/useReportsApi';
import useFilesApi from '../../hooks/backend/api/useFilesApi';
import IBackendError from '../../types/BackendError';
import { Wrapper, WrapperState } from '../Wrapper';
import { reportListColumns } from './ReportListColumns';
import { useReportListConfig } from './ReportListConfig';
import { defaultPage, defaultPageSize } from '../../types/PagingConfig';

interface IMonitoringReportTableItem extends IReportListItem {
  key: string;
}

export const ReportList = () => {
  const unmounted = useRef(false);
  const reportsApi = useReportsApi();
  const filesApi = useFilesApi();
  const {paging, setPaging} = useReportListConfig();
  const [isReferencesLoading, setIsReferencesLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [initError, setInitError] = useState<any>(undefined);
  const [wrapperState, setWrapperState] = useState<WrapperState | null>(null);
  const [reports, setReports] = useState<IMonitoringReportTableItem[]>([]);
  const {currentUser} = useAuth();
  const [totalRows, setTotalRows] = useState<number>(0);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  async function getReports() {
    try {
      setInitError(undefined);
      setLoading(true);

      const reports = await reportsApi.getReports(
        paging.page,
        paging.pageSize);

      if (!unmounted.current) {
        const convertedReports: IMonitoringReportTableItem[] = reports.list.map(
          (r) => ({
            ...r,
            key: r.id,
          })
        );
        setTotalRows(reports.totalRowCount);
        setReports(convertedReports);
      }
    } catch (error) {
      if (!unmounted.current) {
        setInitError(error);
      }
    } finally {
      if (!unmounted.current) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (currentUser?.canViewMonitoring()) {
      getReports();
    }
  }, [currentUser, paging]);

  useEffect(() => {
    if (currentUser === null) {
      setWrapperState('loading');
    } else if (!currentUser.canViewMonitoring()) {
      setWrapperState('accessDenied');
    } else if (initError) {
      setWrapperState('hasErrors');
    } else {
      setWrapperState(null);
    }
  }, [currentUser, initError]);

  function handleOnChange(pagination: TablePaginationConfig) {
    setPaging(pagination.current ?? defaultPage, pagination.pageSize ?? defaultPageSize);
  }

  async function onDownloadClick(id: string) {
    var data = await filesApi.getFile(id);
    saveFile(data, 'application/xml', `MonitoringSignalReport${id}`, 'xml');
  }

  const renderTable = () => {
    if (!currentUser) {
      return null;
    }
    return (
      <Table
        className="u-mt-4"
        columns={reportListColumns(onDownloadClick)}
        loading={isLoading || isReferencesLoading}
        dataSource={reports}
        scroll={{ x: 900 }}
        onChange={handleOnChange}
        pagination={{
          current: paging.page,
          position: ['bottomCenter'],
          pageSize: paging.pageSize,
          total: totalRows,
          showSizeChanger: false
        }}
      />
    );
  };

  return (
    <Wrapper state={wrapperState} error={initError}>
      <PageHeader title="Сигнальные отчеты" />
      {renderTable()}
    </Wrapper>
  );
};